
import { ContactData } from '@/Model/shared/types';
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import OffCanvas from '@/commoncomponents/OffCanvas.vue';
import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import headerStore from '@/store/modules/headerStore';

@Component({
  components: {
    OffCanvas
  }
})
export default class ContactsDetails extends Vue {
  private profilesAdminViewEnabled: boolean = false;

  @PropSync('showDetailsPanel', { type: Boolean, required: true }) showDetails!: boolean;
  @Prop() guardianData!: ContactData;
  @Prop() selectedParticipants!: { firstName: string; lastName: string; scholarGuid: string; type: string }[];

  @Watch('showDetailsPanel', { immediate: true })
  onShowDetails(showDetailsPanel: boolean) {
    // TODO: Once Profiles is on prod and stable delete this whole block of code
    if (!this.profilesAdminViewEnabled) {
      if (!showDetailsPanel) {
        const userRoles: any = APP_UTILITIES.getCookie('user_role');
        const userCookiesUserRole: any = APP_UTILITIES.getCookie('user_cookies_user_role');
        const userData = JSON.parse(userRoles);
        userData[0].roleId = Number(userCookiesUserRole);
        APP_UTILITIES.setCookie('user_role', JSON.stringify(userData), 1);
      } else {
        this.prepareRedirectToEnrollment();
      }
    }
  }

  async created(): Promise<void> {
    this.profilesAdminViewEnabled = await APP_UTILITIES.getFeatureFlag(APP_CONST.FEATURE_KEYS.profilesAdminView);
  }

  updated() {
    this.guardianData.secondaryPhone = String(APP_UTILITIES.formatNumber(this.guardianData.secondaryPhone) || '--');
  }

  private prepareRedirectToEnrollment(): void {
    const { roleId } = APP_UTILITIES.coreids();
    const accountId = APP_UTILITIES.getCookie('accountId');
    const userRoles: any = APP_UTILITIES.getCookie('user_role');
    const oktaRoleId = APP_UTILITIES.getCookie('okta_user_role');
    const userData = JSON.parse(userRoles);
    userData[0].roleId = Number(oktaRoleId);

    if (roleId === APP_CONST.ROLE_TYPE_SUPER_ADMIN || roleId === APP_CONST.ROLE_TYPE_BX_ADMIN) {
      userData[0].roleId = APP_CONST.ACCOUNT_ADMIN_ROLE_ID;
      userData[0].accountId = accountId;
      APP_UTILITIES.setCookie('super_user_drilldown', 'true', 1);
      APP_UTILITIES.setCookie('super_user_drilldown_to_account', 'true', 1);
      headerStore.mutateBxState(true);
    }
    APP_UTILITIES.setCookie('user_role', JSON.stringify(userData), 1);
  }

  handleViewRedirect(e: Event): void {
    e.preventDefault();

    if (this.profilesAdminViewEnabled) {
      //const { guardianGuid } = this.guardianData; TODO: Migration work
      const CONTACT_ID = 4; // TODO: Migration work to be able to get the id from the contact
      window.location.href = `${process.env.VUE_APP_BASE_V2_ADMIN_REDIRECT_URL}profile/contact/${CONTACT_ID}?redirectTo=account/contacts`;
    } else {
      window.location.href = `${process.env.VUE_APP_BASE_ENROLLMENT_REDIRECT_URL}family-details/guardian?redirectTo=contacts`;
    }
  }
  handleEditRedirect(e: Event): void {
    e.preventDefault();

    if (this.profilesAdminViewEnabled) {
      //const { guardianGuid } = this.guardianData; TODO: Migration work
      const CONTACT_ID = 4; // TODO: Migration work to be able to get the id from the contact
      window.location.href = `${process.env.VUE_APP_BASE_V2_ADMIN_REDIRECT_URL}profile/contact/${CONTACT_ID}?redirectTo=account/contacts&editMode=true`;
    } else {
      window.location.href = `${process.env.VUE_APP_BASE_ENROLLMENT_REDIRECT_URL}family-details/guardian?mode=edit&redirectTo=contacts`;
    }
  }
}
