

























import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import UIkit from "uikit";

@Component({})
export default class OffCanvas extends Vue {

  @PropSync('isVisible') isVisibleSync !: boolean;

  @Prop({ default: true }) shouldFocusFirstField !: boolean;

  @Prop({ default: false }) shouldHideOverflowOnMobile !: boolean;

  @Ref('offcanvas') readonly offcanvasElement!: HTMLElement;

  @Watch('isVisible', { immediate: true })
  onIsVisible(isVisible: boolean) {
    const isElementShowing = UIkit.offcanvas(this.$el).isToggled();

    if (isVisible && isElementShowing === false) {
      UIkit.offcanvas(this.$el).show();
    }
    else if (isVisible === false && isElementShowing) {
      UIkit.offcanvas(this.$el).hide();
    }
  }

  mounted() {
    UIkit.util.on(this.$el, 'hidden', ((event: Event) => {
      if (event.target !== this.offcanvasElement) {
        return;
      }
      this.isVisibleSync = false;
      this.$emit('onClose', event);
    }));

    UIkit.util.on(this.$el, 'beforehide', ((event: Event) => {
      if (event.target !== this.offcanvasElement) {
        return;
      }
      this.$emit('onBeforeClose', event);

      if (event.defaultPrevented && this.isVisibleSync === false) {
        this.isVisibleSync = true;
      }
    }));

    UIkit.util.on(this.$el, 'shown', ((event: Event) => {
      if (event.target !== this.offcanvasElement) {
        return;
      }
      if (this.shouldFocusFirstField) {
        const firstElement = this.$el.querySelector('input');
        if (firstElement) {
          firstElement.focus();
        }
      }
    }));
  }
}

